<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>수금 현황</h5>
        <!-- 조회기간 -->
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                @change="setEndDate($event)"
                :value="end_date"
              />
            </div>
          </div>
        </div>

        <!-- 매출처 검색 -->
        <div>
          <div class="input_search">
            <input
              type="text"
              placeholder="매출처 검색"
              :value="searchText"
              @input="typing($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <!-- 구분 필터 -->
        <div>
          <span class="option_txt">수금 구분</span>
          <div class="input_text">
            <my-local-selectric
              v-if="showSelectric"
              :id="'collection_type_selectric'"
              :watch="searchType"
              :options="collection_options_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filtered_collections.length }}건</h6>
      <!-- 테이블 -->
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>수금일</th>
              <th>매출처명</th>
              <th>수금액</th>
              <th>수금구분</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(collection, index) in filtered_collections"
              :key="collection.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ collection.input_date }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, collection.company_id).name }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(collection.total_value) }}
              </td>
              <td>
                {{
                  findInfoFromId(collectionType, collection.collection_type_id)
                    .name
                }}
              </td>
              <td v-show="managementMode">
                <button @click="ShowModal(index)" class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 세부정보 수정 -->
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label>매출처</label>
              <div class="input_search">
                <input
                  class="position"
                  type="text"
                  placeholder="매출처 검색"
                  :readonly="managementData.company_id != null"
                  :value="
                    findInfoFromId(companys, managementData.company_id).name
                  "
                  :disabled="!managementMode || selectedIndex == -1"
                />
                <div v-show="managementMode" v-if="isPermissionOn">
                  <button>
                    <i
                      class="fa fa-search"
                      v-show="selectedIndex != -1"
                      @click="showCompanySearch = true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- 수금일 -->
          <div>
            <div class="input_text">
              <label>수금일</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="managementData.input_date"
                :readonly="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>

          <!-- 수금액 -->
          <div>
            <div class="input_text">
              <label>수금액</label>
              <input
                type="text"
                placeholder=""
                :value="$makeComma(managementData.total_value)"
                inputmode="decimal"
                @input="$inputNumberInt($event, managementData, 'total_value')"
                :disabled="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>

          <!-- 수금구분 -->
          <div>
            <div class="input_text">
              <label>수금구분</label>
              <div>
                <my-local-selectric
                  v-if="showSelectric"
                  :id="'collection_selectric'"
                  :watch="managementData.collection_type_id"
                  :options="collection_options"
                  @changeValue="setManagementCollectionType($event)"
                  :class="
                    managementMode && selectedIndex != -1 ? '' : 'disabled'
                  "
                >
                </my-local-selectric>
              </div>
            </div>
          </div>
          <div
            v-if="
              collection_types
                .filter(x => x.date_yn)
                .map(x => x.id)
                .includes(managementData.collection_type_id)
            "
          >
            <div class="input_text">
              <label>상환기간</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="managementData.collecting_date"
                :disabled="!managementMode || selectedIndex == -1"
                :readonly="!managementMode || selectedIndex == -1"
              />
            </div>
          </div>
          <div
            class="btn_wrap"
            v-show="managementMode && selectedIndex != -1"
            v-if="isPermissionOn"
          >
            <button
              class="btn_sub2"
              :disabled="isValidModify"
              @click="submitForm()"
            >
              수정
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteCompany($event)"
    ></two-button-modal>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
import PriceMixin from '@/mixins/price';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, FETCH_MIXIN, PriceMixin],
  components: {
    MyLocalSelectric,
    TwoButtonModal,
    CompanySearch,
  },
  data() {
    return {
      showCompanySearch: false,
      showSelectric: false,
      searchText: '',

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCollectionPage',
      collection_types: 'getCollectionType',
      selectedIndex: 'getSelectedIndexFromCollection',
      managementData: 'getManagementDataFromCollection',
      cloneData: 'getCloneDataFromCollection',
      collections: 'getCollection',
      searchType: 'getSearchTypeFromCollection',
      collectionType: 'getCollectionType',
      collection_options: 'getCollectionTypeForSelectric',
      collection_options_all: 'getCollectionTypeForSelectricAll',
      // 매출처 검색
      companys: 'getCompany',
      start_date: 'getStartDateFromCollection',
      end_date: 'getEndDateFromCollection',
    }),
    filtered_collections() {
      const Hangul = require('hangul-js');
      return this.collections
        .filter(
          x =>
            x.collection_type_id == this.searchType || this.searchType == null,
        )
        .filter(
          x =>
            this.findInfoFromId(this.companys, x.company_id).name.includes(
              this.searchText,
            ) ||
            Hangul.d(
              this.findInfoFromId(this.companys, x.company_id).name,
              true,
            )
              .map(x => x[0])
              .join('')
              .includes(this.searchText),
        );
    },
    isValidModify() {
      if (this.managementMode) {
        let modifyData = this.lodash.clonedeep(this.managementData);

        if (modifyData == undefined) return true;

        if (isNaN(modifyData.total_value) == false) {
          modifyData.total_value = this.$makeComma(modifyData.total_value);
        }
        modifyData = JSON.stringify(modifyData);

        let originData = this.cloneData;
        if (isNaN(originData.total_value) == false) {
          originData.total_value = this.$makeComma(originData.total_value);
        }
        originData = JSON.stringify(originData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    checkValidType() {
      return this.managementData.collection_type_id == null ? false : true;
    },
    checkValidTotalValue() {
      if (
        this.managementData.total_value == undefined ||
        this.managementData.total_value == null ||
        String(this.$makeNumber(this.managementData.total_value)).trim() ==
          '0' ||
        String(this.managementData.total_value).trim() == ''
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    // 매출처 검색
    typing(e) {
      this.searchText = e.target.value.trim();
      this.SelectRow(-1);
    },
    pushManagementCompany(arg) {
      if (this.showCompanySearch) {
        this.showCompanySearch = false;
      }
      this.managementData.company_id = arg.id;
    },

    SelectRow(index) {
      this.$store.commit('setSelectedIndexToCollection', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToCollection', {
          id: -1,
          company_id: -1,
          collection_type_id: null,
          total_value: null,
          input_date: '',
        });
      } else {
        this.$store.commit(
          'setManagementDataToCollection',
          this.lodash.clonedeep(this.filtered_collections[index]),
        );
        this.$store.commit(
          'setCloneDataToCollection',
          this.lodash.clonedeep(this.filtered_collections[index]),
        );
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToCollection', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToCollection', e.target.value);
          this.SelectRow(-1);
          await this.FetchCollection();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToCollection', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToCollection', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToCollection', e.target.value);
          this.SelectRow(-1);
          await this.FetchCollection();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToCollection', yyyymmdd(new Date()));
      }
    },

    setManagementCollectionType(e) {
      this.managementData.collection_type_id = e;
    },

    async FetchCollection() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_COLLECTION', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValidTotalValue && this.checkValidType) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.total_value = this.$makeNumber(payload.total_value);

        this.showSpinner();
        await this.$store
          .dispatch('UPDATE_COLLECTION', payload)
          .then(() => {
            this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
            // 리스트 업데이트
            this.FetchCollection();
            // 초기화
            this.$store.commit('setManagementDataToCollection', {
              id: -1,
              company_id: -1,
              collection_type_id: null,
              total_value: 0,
              input_date: '',
            });
            this.$store.commit('setCloneDataToCollection', {
              id: -1,
              company_id: -1,
              collection_type_id: null,
              total_value: 0,
              input_date: '',
            });
            this.SelectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '저장 중 에러',
              '수금 등록 저장 중 에러발생',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        if (!this.checkValidTotalValue) {
          this.openOneButtonModal(
            '수금액 수정 오류',
            '수금액은 1원이상 입력해주세요.',
          );
        } else if (!this.checkValidType) {
          this.openOneButtonModal(
            '수정 오류',
            '수금 구분을 반드시 선택해 주세요.',
          );
        }
      }
    },
    async deleteCompany(arg1) {
      if (arg1 == undefined) return;
      this.showSpinner();
      //박스 삭제
      this.$store
        .dispatch('DELETE_COLLECTION', this.managementData.id)
        .then(() => {
          this.FetchCollection();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    changeSelectric(type) {
      this.SelectRow(-1);
      this.$store.commit('setSearchTypeToCollection', type);
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToCollection', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToCollection', yyyymmdd(lastMonth));
    }

    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }

    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처 리스트');
    }

    await this.FetchCollection();

    this.showSelectric = true;
  },
};
</script>

<style scoped></style>

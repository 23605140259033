<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>수금 등록</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label>매출처</label>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="매출처 검색"
                  :readonly="newData.company_id != null"
                  :value="findInfoFromId(companys, newData.company_id).name"
                />
                <button>
                  <i class="fa fa-search" @click="showCompanySearch = true"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- 수금일 -->
          <div>
            <div class="input_text">
              <label>수금일</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="newData.input_date"
              />
            </div>
          </div>

          <!-- 수금액 -->
          <div>
            <div class="input_text">
              <label>수금액</label>
              <input
                type="text"
                placeholder=""
                :value="$makeComma(newData.total_value)"
                inputmode="decimal"
                @input="$inputNumberInt($event, newData, 'total_value')"
              />
            </div>
          </div>

          <!-- 수금구분 -->
          <div>
            <div class="input_text">
              <label>수금구분</label>
              <div>
                <my-selectric
                  v-if="showSelectric"
                  :id="'collection_selectric'"
                  :watch="newData.collection_type_id"
                  :options="collection_options"
                  :commit="'setNewCollectionTypeId'"
                >
                </my-selectric>
              </div>
            </div>
          </div>

          <div
            v-if="
              collection_types
                .filter(x => x.date_yn)
                .map(x => x.id)
                .includes(newData.collection_type_id)
            "
          >
            <div class="input_text">
              <label>상환기간</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="newData.collecting_date"
              />
            </div>
          </div>

          <div class="btn_wrap">
            <button
              class="btn_sub2"
              :disabled="checkValid"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
  </div>
</template>
MySelectric
<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/price';
import FETCH_MIXIN from '@/mixins/fetch';
import MySelectric from '@/layouts/components/MySelectric';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, PriceMixin, FETCH_MIXIN],
  data() {
    return {
      showSelectric: false,
      showCompanySearch: false,
    };
  },
  components: {
    MySelectric,
    CompanySearch,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCollectionPage',
      newData: 'getNewDataFromCollection',
      collection_options: 'getCollectionTypeForSelectric',
      collection_types: 'getCollectionType',
      selectedIndex: 'getSelectedIndexFromCollection',
      start_date: 'getStartDateFromCollection',
      end_date: 'getEndDateFromCollection',
      // 매출처 검색
      companys: 'getCompany',
    }),
    checkValid() {
      const companyId = this.newData.company_id;
      const totalValue = this.newData.total_value;
      const collectionType = this.newData.collection_type_id;

      const blankChk = [
        { chk: companyId, data: -1 }, // 거래처
        { chk: totalValue, data: null }, // 수금액
        { chk: collectionType, data: null }, // 수금구분
      ];

      for (let i = 0; i < blankChk.length; i++) {
        if (blankChk[i].chk == blankChk[i].data) {
          return true;
        }
      }
      return false;
    },
    chkTotalValueZero() {
      if (this.newData.total_value == 0 || this.newData.total_value == '0') {
        return true;
      } else return false;
    },
  },
  methods: {
    // 매출처 검색
    pushNewCompany(arg) {
      this.newData.company_id = arg.id;
      this.showCompanySearch = false;
    },
    async submitForm() {
      if (this.chkTotalValueZero) {
        this.openOneButtonModal(
          '수금액 등록 오류',
          '수금액은 1원이상 입력해주세요.',
        );
        return;
      }
      const today = yyyymmdd(new Date());

      const payload = this.lodash.clonedeep(this.newData);
      payload.total_value = this.$makeNumber(payload.total_value);

      this.showSpinner();
      await this.$store
        .dispatch('INSERT_COLLECTION', payload)
        .then(() => {
          this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');

          // 리스트 업데이트
          this.FETCH_WITH_PAYLOAD(
            'FETCH_COLLECTION',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '수금 리스트',
          );
          // 초기화
          this.$store.commit('setNewDataToCollection', {
            company_id: -1,
            collection_type_id: null,
            total_value: 0,
            input_date: today,
            collecting_date: null,
          });
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('저장 중 에러', '수금 등록 저장 중 에러발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },

  async created() {
    if (this.start_date == null) {
      const today = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setEndDateToCollection', yyyymmdd(today));
      this.$store.commit('setStartDateToCollection', yyyymmdd(lastMonth));
    }

    if (this.newData.input_date == '') {
      this.newData.input_date = yyyymmdd(new Date());
    }
    if (this.newData.collecting_date == null) {
      this.newData.collecting_date = yyyymmdd(new Date());
    }

    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }

    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
